<app-header></app-header>

<div class="root">
  <app-side-menu *ngIf="auth.isLoggedIn()"></app-side-menu>
  <div class="wrapper">
    <router-outlet style="display: none"></router-outlet>
  </div>
</div>

<app-loading></app-loading>
