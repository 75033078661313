import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';
import { GlobalService } from '../global.service';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  url = `${environment.baseUrl}/imagem/upload`;

  constructor(
    public global: GlobalService,
    public auth: AuthService,
    public http: HttpClient
  ) {}

  upload(fileToUpload: File, fileName: string = 'image') {
    const formData: FormData = new FormData();
    formData.append('image', fileToUpload, fileToUpload.name);

    const options = {
      method: 'POST',
      httpParams: new HttpParams(),
    };

    const req = new HttpRequest(options.method, this.url, formData, {
      params: options.httpParams,
      reportProgress: true,
    });

    return this.http.request(req).pipe(
      map((r) => r),
      catchError((e) => this.handleError(e))
    );
  }

  handleError(error: HttpErrorResponse) {
    return throwError(() => error);
  }
}
