import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CadastroGeral } from '@models/cadastro-geral.model';
import { AuthService } from '@services/auth.service';
import { CadastroGeralService } from '@services/class/cadastro-geral.service';
import { UsuarioService } from '@services/class/usuario.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {
  cadastroGeral: CadastroGeral = new CadastroGeral();

  constructor(
    private cadastroGeralService: CadastroGeralService,
    public auth: AuthService,
    private usuarioService: UsuarioService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.auth.user?.status !== 'P') {
      this.router.navigate(['/']);
    }

    this.getUser();
    this.getCadastroGeral();
  }

  getUser() {
    this.usuarioService.getById(this.auth.user.id).subscribe((res) => {
      if (res.status === 'R') {
        this.auth.logout();
        alert('Usuário reprovado, contate o suporte');
        return;
      }

      if (res.id) {
        this.auth.user = res;
        this.auth.setUser(res);

        if (res.status === 'A') {
          this.router.navigate(['/']);
        }
      }
    });
  }

  getCadastroGeral() {
    this.cadastroGeralService
      .get()
      .subscribe((res) => (this.cadastroGeral = res));
  }

  openWhatsApp() {
    window.open(`https://wa.me/55${this.cadastroGeral?.whatsApp}`, '_blank');
  }
}
