import { Autenticacao } from './autenticacao.model';
import { Cliente } from './cliente.model';

export class Usuario {
  id?: number = undefined;
  nome = '';
  email = '';
  senha = '';
  celular = '';
  cpf = '';
  cnpj = '';
  cnpjEmpresa = '';
  situacao: 'A' | 'I' = 'A';
  dataHoraCriacao = new Date();
  status: 'P' | 'A' | 'R' = 'A';
  imagem = '';
  dataNascimento: Date | null = null;
  // endereco
  cep = '';
  uf = '';
  cidade = '';
  bairro = '';
  endereco = '';
  enderecoNumero = '';
  complemento = '';
  latitude = 0;
  longitude = 0;
  // aa
  arquivoDocumento = '';
  arquivoDocumentoVerso = '';
  indCliente: 'S' | 'N' = 'N';
  indMotorista: 'S' | 'N' = 'N';
  indChappa: 'S' | 'N' = 'N';
  indAdmin: 'S' | 'N' = 'N';
  idNotificacao = '';
  cliente = new Cliente();
  autenticacao = new Autenticacao();
  _numeroViagens = 0;
  _nota = 5;

  // front
  re_senha?: string;
}
