<div class="upload-section">
  <span class="upload-section__label" *ngIf="label">
    {{ label }}
  </span>
  <a class="upload-section__image">
    <button class="delete" *ngIf="src" (click)="removeImage()" type="button">
      <i class="fas fa-trash"></i>
    </button>
    <div class="overlay" *ngIf="!src" (click)="fileInput.click()">
      <i class="fas fa-upload"></i>
      <span>Fazer upload</span>
    </div>
    <img
      [src]="src ? global.imageUrl + src : 'assets/image-placeholder.png'"
      class="upload-section__image"
      [alt]="label"
    />
  </a>
  <input
    hidden
    (change)="onFileSelected($event)"
    #fileInput
    type="file"
    [id]="fileName"
  />
</div>
