<header class="header">
  <div class="container">
    <nav>
      <a routerLink="/" class="logo">
        <img src="assets/logo.png" alt="Logo" />
      </a>
      <div class="header-actions">
        <button
          mat-button
          [matMenuTriggerFor]="menu"
          class="header-client-user-toggle"
        >
          <div class="header-client-user-toggle-wrapper">
            <img
              [src]="global.imageUrl + usuarioCliente?.cliente?.imagem"
              onerror="this.src = 'assets/image-placeholder.png'"
              [alt]="usuarioCliente?.cliente?.nome"
            />
            {{ usuarioCliente?.cliente?.nome }}
          </div>
        </button>
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            *ngFor="let item of auth.usuariosCliente"
            (click)="onUsuarioClienteChange(item)"
            [disabled]="item?.id === usuarioCliente?.id"
          >
            {{ item?.cliente?.nome }}
          </button>
        </mat-menu>
        <div class="header-user">
          <h3 class="header-user__name">
            Olá, <span>{{ auth.user.nome.split(" ")[0] }}</span>
          </h3>
          <div class="avatar">
            <img src="assets/icon.png" alt="Chappa" />
          </div>
          <div class="logout">
            <a class="header-menu__link" (click)="auth.logout()">
              <i class="fas fa-sign-out-alt"></i>
              <span>Sair</span>
            </a>
          </div>
        </div>
      </div>
    </nav>
  </div>
</header>
<!-- <a class="header-menu__link" (click)="auth.logout()"> -->
