import { firstValueFrom } from 'rxjs';
import { AuthService } from '../services/auth.service';

export function initializeApp(auth: AuthService) {
  return () =>
    new Promise(async (resolve) => {
      // async requests

      const isLoggedIn = auth.isLoggedIn();

      if (isLoggedIn) {
        await firstValueFrom(auth.getUsuariosCliente());
      }

      resolve(true);
    });
}
