import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { Usuario } from 'src/app/models/usuario.model';
import { DefaultGetProps, FetchProps, SituationInput } from 'src/app/utils';

const BASE_URL = '/usuario';

@Injectable({
  providedIn: 'root',
})
export class UsuarioService {
  constructor(public api: ApiService) {}

  get(props: UserGetProps): Observable<FetchProps<Usuario>> {
    return this.api.get<FetchProps<Usuario>>(`${BASE_URL}/buscar`, props);
  }

  getById(id: number) {
    return this.api.get(`${BASE_URL}/buscar?id=${id}`);
  }

  post(usuario: Usuario) {
    return this.api.post(`${BASE_URL}/inserir`, usuario);
  }

  patch(usuario: Usuario) {
    return this.api.post(`${BASE_URL}/alterar`, usuario);
  }

  delete(usuario: Usuario) {
    return this.api.post(`${BASE_URL}/deletar`, usuario);
  }

  deleteSelected(usuarios: Usuario[]) {
    return this.api.post(`${BASE_URL}/deletarLista`, usuarios);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

  indicator(params?: GetIndicatorProps) {
    return this.api.get<number>(`${BASE_URL}/buscarIndicador`, params);
  }

  signUpClient(usuario: Usuario) {
    return this.api.post(`${BASE_URL}/inserirCliente`, usuario);
  }

  existsEmail(params?: {
    id?: number;
    email?: string;
    operacao?: 'insert' | 'update';
  }) {
    return this.api.get(`${BASE_URL}/existeEmail`, {
      id: params?.operacao === 'insert' ? -99 : params?.id,
      ...params,
    });
  }
}

interface GetIndicatorProps {
  status?: string;
  indCliente?: string;
  indMotorista?: string;
  indChappa?: string;
  indAdmin?: string;
  idCliente?: number;
}

export interface UserGetProps extends DefaultGetProps {
  situacao?: SituationInput;
  status?: string;
  indCliente?: string;
  indMotorista?: string;
  indChappa?: string;
  indAdmin?: string;
  idCliente?: number;
}
