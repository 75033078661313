export class CadastroGeral {
  id = undefined;
  whatsApp = '';
  telefone = '';
  email = '';
  // redes sociais
  linkFacebook = '';
  linkInstagram = '';
  linkLinkedin = '';
  // endereço
  cep = '';
  uf = '';
  cidade = '';
  endereco = '';
  enderecoNumero = '';
  bairro = '';
  complemento = '';
  // termos
  politicasDePrivacidade = '';
  termosDeUso = '';
}
