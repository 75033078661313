import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isLoggedIn = this.auth.isLoggedIn();
    const token = this.auth.getTokenStorage();

    if (isLoggedIn && token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Basic ` + token,
        },
      });
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          this.handleError(error);
        }

        return throwError(() => error);
      })
    );
  }

  handleError(error: HttpErrorResponse) {
    let message: string | undefined = undefined;

    if (error.status === 401) {
      this.auth.logout();
      this.router.navigate(['/sign-in']);
    }

    if (error.status === 500) {
      message = (error?.error ||
        error?.message ||
        'Erro interno do servidor.') as string;
    }

    if (error?.status === 404) {
      message = 'Não foi possível encontrar o recurso que você solicitou.';
    }

    if (message) {
      console.error(message);
    }
  }
}
