<div class="otp-container">
  <input
    #inputEl
    *ngFor="let input of inputs.controls; let i = index"
    (focus)="handleFocus($event)"
    (blur)="onTouched?.()"
    (keypress)="handleKeyPress($event, i)"
    (input)="handleInput()"
    (keydown)="handleKeyDown($event, i)"
    [formControl]="$any(input)"
    type="tel"
    inputmode="numeric"
    [attr.autocomplete]="i === 0 ? 'one-time-code' : null"
    (paste)="handlePaste($event, i)"
    [name]="'otp-input-' + i"
  />
</div>
