import { Component, OnDestroy, OnInit } from '@angular/core';
import { CadastroGeral } from '@models/cadastro-geral.model';
import { UsuarioCliente } from '@models/usuario-cliente.model';
import { AuthService } from '@services/auth.service';
import { CadastroGeralService } from '@services/class/cadastro-geral.service';
import { GlobalService } from '@services/global.service';
import { HelperService } from '@services/helper.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  cadastroGeral = new CadastroGeral();
  usuarioCliente = new UsuarioCliente();

  constructor(
    public auth: AuthService,
    public cadastroGeralService: CadastroGeralService,
    public helper: HelperService,
    public global: GlobalService
  ) {
    this.usuarioCliente = this.auth.usuarioCliente;
  }

  ngOnInit(): void {
    this.auth.usuarioClienteChange.subscribe({
      next: (value) => {
        if (value?.id !== this.usuarioCliente.id) {
          this.usuarioCliente = value;
        }
      },
    });

    this.getCadastroGeral();
  }

  ngOnDestroy(): void {}

  getCadastroGeral() {
    this.cadastroGeralService
      .get()
      .subscribe((res) => (this.cadastroGeral = res));
  }

  onUsuarioClienteChange(event: UsuarioCliente) {
    if (event?.id) {
      this.auth.setUsuarioCliente(event);
    }
  }
}
