import {
  HttpClient,
  HttpErrorResponse,
  HttpRequest,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  get<T = any>(url: string, params = {}) {
    return this.http
      .get<T>(environment.baseUrl + url, {
        params: params,
      })
      .pipe(map((r) => r));
  }

  post<T = any>(url: string, body = {}, params = {}) {
    return this.http
      .post<T>(environment.baseUrl + url, body)
      .pipe(map((r) => r));
  }

  patch(url: string, body?) {
    return this.http
      .patch(environment.baseUrl + url, body, { withCredentials: true })
      .pipe(map((r) => r));
  }

  put(url: string, body?) {
    return this.patch(url, body);
  }

  delete(url: string, body?) {
    return this.http
      .delete(environment.baseUrl + url, {
        params: body,
        withCredentials: true,
      })
      .pipe(map((r) => r));
  }

  postFile(fileToUpload: File, url: string, fileName: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append(fileName, fileToUpload, fileToUpload.name);

    const options = {
      method: 'POST',
      httpParams: new HttpParams(),
    };

    const req = new HttpRequest(
      options.method,
      environment.baseUrl + url,
      formData,
      {
        params: options.httpParams,
        reportProgress: true,
        withCredentials: true,
      }
    );

    return this.http.request(req).pipe(map((r) => r));
  }

  postAsFormData(
    url: string,
    body: Object,
    files: File[],
    options = {
      method: 'POST',
      httpParams: new HttpParams(),
    }
  ) {
    const formData = new FormData();
    // Coloca todo body no form data.., aproveita e da trim nas strings
    // provavelmente da pra fazer uma função recursiva só pra tratar os dados que são passadas para FormData
    Object.keys(body).forEach((k) => {
      if (typeof body[k] === 'string') {
        body[k] = body[k].trim();
      }
      if (Array.isArray(body[k])) {
        body[k].forEach((val) => {
          formData.append(k + '[]', val);
        });
      } else if (typeof body[k] === 'object' && body[k] !== null) {
        Object.keys(body[k]).forEach((l) => {
          formData.append(k + '[' + l + ']', body[k][l]);
        });
      } else if (body[k] === null) {
        formData.append(k, '');
      } else {
        formData.append(k, body[k]);
      }
    });

    // Coloca todos files no FormData
    files.forEach((file: File, i) => {
      formData.append('image' + '[' + i + ']', file, file.name);
    });

    const req = new HttpRequest(
      options.method,
      environment.baseUrl + url,
      formData,
      {
        params: options.httpParams,
        reportProgress: true,
        withCredentials: true,
      }
    );

    return this.http.request(req).pipe(map((r) => r));
  }
}
