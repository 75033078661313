import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  // apiUrl: string = 'http://localhost:50633';
  imageUrl: string = 'https://apiv2.chappa.com.br/';

  constructor() { }
}