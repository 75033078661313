<div class="welcome">
  <div class="welcome-box">
    <img src="assets/waiting.png" alt="Perfil em análise" />
    <h1>Perfil em análise</h1>
    <p>
      Nossa equipe está trabalhando diligentemente para analisar todas as
      informações fornecidas e garantir que tudo esteja em ordem. Nosso objetivo
      é fornecer um serviço/produto de alta qualidade, e para isso, estamos
      seguindo um processo de aprovação rigoroso.
    </p>
    <div class="buttons">
      <button mat-raised-button color="primary" (click)="openWhatsApp()">
        Entrar em contato
      </button>
      <button mat-stroked-button (click)="auth.logout()">Sair</button>
    </div>
    <div class="logo">
      <img src="assets/logo.png" alt="Chappa" />
    </div>
  </div>
</div>
